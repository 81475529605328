import { useEffect, useState } from 'react';
import Divider from '../../Atoms/Divider/Divider';
import Dots from '../../Atoms/Loaders/Dots';
import { useKexNavigate } from '../../Kex/KexRouter/KexRouter';
import Accordion from '../../Molecules/Accordion/Accordion';
import OrderInfoViewModel from '../../Order/Models/OrderInfoViewModel.interface';
import RecurringOrderViewModel from '../../Order/Models/RecurringOrderViewModel.interface';
import { GetOrder, GetOrders } from '../../OrderComponent/Order';
import OrderListing from '../../OrderComponent/OrderListing';
import { AddItemsToCart } from '../../OrderComponent/RecurringOrder';
import RecurringOrderRowListing from '../../OrderComponent/RecurringOrderRowListing';
import RecurringOrdersPageModel from '../../RecurringOrdersPage/Models/RecurringOrdersPageModel.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import { styled } from '../../stitches.config';

function RecurringOrdersPage() {
  const {
    translations: {
      'customerInfo/myOrders/noOrders': noOrdersLabel,
      'customerInfo/myOrders/noRecurringOrderName': noRecurringOrderNameLabel,
      'recurringOrderCartPage/onHoldText': onHoldLabel,
      'orderHistoryPage/dateString': dateLabel,
    },
    staticPages: { recurringOrderCartPage },
    languageRoute,
    requestToken,
  } = useAppSettingsData();

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const currentPage = useCurrentPage<RecurringOrdersPageModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const kexNavigate = useKexNavigate();
  const { customerId, organizationId } = useUserStateData();
  const url = `/api/${
    languageRoute || 'en'
  }/order/GetRecurringOrders?customerId=${customerId}`;
  const [orders, setOrders] = useState<RecurringOrderViewModel[]>();

  const fetchData = async (loadMoreUrl?: string) => {
    if (customerId) {
      let endpoint =
        loadMoreUrl || `${url}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
      const data: OrderInfoViewModel = await GetOrders(
        currentPage.channelId,
        endpoint,
        setIsLoading
      );

      if (data) {
        setOrders(data.recurringOrders);
        setTotalCount(data.recurringOrdersCount);
      }
    }
  };

  async function handleChangePage(value: any) {
    setPageIndex(value);

    const newUrl = `${url}&pageIndex=${value}&pageSize=${pageSize}`;

    fetchData(newUrl);
  }

  const handleChangePageSize = (value: any) => {
    setPageSize(value);
    const newUrl = `${url}&pageIndex=${pageIndex}&pageSize=${value}`;
    fetchData(newUrl);
  };

  const handleGetOrder = async (id: string) => {
    const url = `/api/${
      languageRoute || 'en'
    }/recurringorder/GetOrder?organizationId=${organizationId}&recurringOrderId=${id}`;

    const data = await GetOrder(currentPage.channelId, url, setIsLoading);

    if (data?.order) {
      await AddItemsToCart(
        currentPage.channelId,
        languageRoute,
        data.order.items
      );
    }

    kexNavigate(`${recurringOrderCartPage}?recurringOrderId=${id}`);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage.channelId]);

  return (
    <main>
      {orders && !!orders.length ? (
        <>
          <OrderListing
            heading={currentPage.pageTitle}
            totalCount={totalCount}
            introText={currentPage.pageIntroText}
            changePage={handleChangePage}
            pageSize={pageSize}
            pageIndex={pageIndex}
            changePageSize={handleChangePageSize}
          >
            {orders.map((order: RecurringOrderViewModel) => {
              const title = (
                <div>
                  <StyledAccountKexLink
                    onClick={() => handleGetOrder(order.id)}
                  >
                    {order.name ?? noRecurringOrderNameLabel}asd
                  </StyledAccountKexLink>
                  {order.onHold && (
                    <ApproveText>{` - ${onHoldLabel}`}</ApproveText>
                  )}
                </div>
              );
              return (
                <div key={order.id}>
                  <Accordion title={title}>
                    <RecurringOrderRowListing order={order} />
                  </Accordion>
                  <MaxDivider />
                </div>
              );
            })}
          </OrderListing>
        </>
      ) : !isLoading ? (
        <div>{noOrdersLabel}</div>
      ) : (
        <DotsWrapper>
          <Dots />
        </DotsWrapper>
      )}
    </main>
  );
}

const ApproveText = styled('span', {
  fontWeight: '$fw700',
  fontStyle: 'italic',
});

const MaxDivider = styled(Divider, {
  w: '100%',
  h: 0.5,
  opacity: 0.5,
  m: 0,
});

const StyledAccountKexLink = styled('button', {
  fs: 8,
  fontWeight: '$fw700',
  lineHeight: '28px',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const DotsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  pb: 9,
});

export default RecurringOrdersPage;
