import { mutate } from 'swr';
import Fetcher from '../Shared/Common/Fetcher';
let abortController: AbortController = new AbortController();

export async function Add(
  channelId: string,
  requestToken: string,
  languageRoute: string,
  organizationId: string,
  name: string,
  items: any[],
  startDate: string,
  intervalInDays: number,
  setIsLoading: (value: boolean) => void,
  setError: (value: boolean) => void,
  setValidationMessage: (value: string) => void
) {
  setError(false);
  setValidationMessage('');
  setIsLoading(true);
  const res = await fetch(
    `/api/${
      languageRoute || 'en'
    }/recurringorder/add?organizationId=${organizationId}&channelId=${channelId}&name=${name}&startDate=${startDate}&intervalInDays=${intervalInDays}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'litium-request-context': JSON.stringify({
          channelSystemId: channelId,
        }),
        RequestVerificationToken: requestToken,
      },
      body: JSON.stringify(items),
    }
  );

  setIsLoading(false);

  const data = await res.json();

  if (!res.ok) {
    setError(true);
    setValidationMessage(data.message);
  }

  return data;
}

export async function Update(
  channelId: string,
  requestToken: string,
  languageRoute: string,
  organizationId: string,
  recurringOrderId: string,
  name: string,
  items: any[],
  startDate: string,
  intervalInDays: number,
  onHold: boolean,
  setIsLoading: (value: boolean) => void,
  setError: (value: boolean) => void,
  setValidationMessage: (value: string) => void
) {
  setError(false);
  setValidationMessage('');
  setIsLoading(true);
  const res = await fetch(
    `/api/${
      languageRoute || 'en'
    }/recurringorder/update?organizationId=${organizationId}&recurringOrderId=${recurringOrderId}&name=${name}&startDate=${startDate}&intervalInDays=${intervalInDays}&onHold=${
      onHold ? 'true' : 'false'
    }`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'litium-request-context': JSON.stringify({
          channelSystemId: channelId,
        }),
        RequestVerificationToken: requestToken,
      },
      body: JSON.stringify(items),
    }
  );

  setIsLoading(false);

  const data = await res.json();

  if (!res.ok) {
    setError(true);
    setValidationMessage(data.message);
  }

  return data;
}

export async function GetOrder(channelId: string, url: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;
  const litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });
  return Fetcher<any, any>(
    url,
    signal,
    (data, resolve) => {
      resolve(data);
    },
    litiumContext
  );
}

export async function Delete(
  channelId: string,
  languageRoute: string,
  organizationId: string,
  recurringOrderId: string
) {
  const res = await fetch(
    `/api/${
      languageRoute || 'en'
    }/recurringorder/delete?organizationId=${organizationId}&recurringOrderId=${recurringOrderId}`,
    {
      method: 'POST',
      headers: {
        'litium-request-context': JSON.stringify({
          channelSystemId: channelId,
        }),
      },
    }
  );
  return res.ok;
}

export async function AddItemsToCart(
  channelId: string,
  languageRoute: string,
  data: any
) {
  const res = await fetch(
    `/api/${languageRoute || 'en'}/recurringorder/AddRecurringOrderItemsToCart`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'litium-request-context': JSON.stringify({
          channelSystemId: channelId,
        }),
      },
      body: JSON.stringify(data),
    }
  );

  if (res.ok) {
    const cartUrl = `/api/${languageRoute || 'en'}/Cart/`;
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart`, cart, false);

    return true;
  }

  return false;
}
