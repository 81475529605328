import Divider from '../Atoms/Divider/Divider';
import OrderRowItem from '../Order/Models/OrderRowItem.interface';
import RecurringOrderViewModel from '../Order/Models/RecurringOrderViewModel.interface';
import { getFormattedDate } from '../Shared/Common/Helpers';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';
import { styled } from '../stitches.config';
import Image from '../Atoms/Image/Image';
import OrderListingProductCard from './OrderListingProductCard';

type PropType = {
  order: RecurringOrderViewModel;
};

function RecurringOrderRowListing({ order }: PropType) {
  const {
    'orderListing/customerInformation': customerInformationLabel,
    'orderListing/products': productsLabel,
    'recurringOrderCartPage/days': daysLabel,
    'recurringOrderCartPage/interval': recurringOrderIntervalLabel,
    'recurringOrderCartPage/date': startDateLabel,
    'customerInfo/myOrders/nextOrderDate': nextOrderLabel,
    'orderListing/totalsum': totalSumLabel,
    'orderListing/paymentmethod': paymentMethodLabel,
  } = useTranslationData();

  const hasActiveOrder =
    order.orderDetails && Number(order.orderDetails.orderStatus) > 0;

  return (
    <>
      <OrderInformation>
        <StyledSpan>{`${startDateLabel}: ${getFormattedDate(
          order.startDate
        )}`}</StyledSpan>
        <StyledSpan>{`${nextOrderLabel}: ${getFormattedDate(
          order.nextOrderDate
        )}`}</StyledSpan>
        <StyledSpan>{`${recurringOrderIntervalLabel}: ${order.intervalInDays} ${daysLabel}`}</StyledSpan>
        {hasActiveOrder && (
          <>
            <StyledSpan>{`${totalSumLabel}: ${order.orderDetails.orderGrandTotal}`}</StyledSpan>
            <StyledSpan>{`${paymentMethodLabel}: ${order.orderDetails.paymentMethod}`}</StyledSpan>
          </>
        )}
      </OrderInformation>
      <CustomerInformation>
        <CustomerInformationContent>
          <HeadingLabel>{customerInformationLabel}</HeadingLabel>
          {hasActiveOrder && (
            <Delivery>
              <StyledSpan>
                {order.orderDetails.customerInfo.address1}
              </StyledSpan>
              <StyledSpan>{`${order.orderDetails.customerInfo.zip} ${order.orderDetails.customerInfo.city}`}</StyledSpan>
            </Delivery>
          )}
        </CustomerInformationContent>
      </CustomerInformation>
      <ProductItems>
        <HeadingLabel>{productsLabel}</HeadingLabel>
        {order.orderDetails.orderRows.map((product: OrderRowItem) => (
          <OrderListingProductCard
            key={product.articleNumber}
            product={product}
          />
        ))}
      </ProductItems>
    </>
  );
}

const ProductItems = styled('div', {
  mt: 9,
});

const OrderInformation = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const StyledSpan = styled('span', {
  mt: 1,
});

const HeadingLabel = styled('span', {
  fs: 8,
  fontWeight: '$fw700',
  lineHeight: '28px',
});

const CustomerInformation = styled('div', {
  mt: 4,
  '@mediaMinMedium': {
    display: 'flex',
  },
});

const Delivery = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const CustomerInformationContent = styled('div', {});

export default RecurringOrderRowListing;
